const { default: axios } = require("axios");

class OpenAIService {
    constructor() {
        this.apiKey = process.env.OPENAI_API_KEY || "";
        this.baseURL = process.env.OPEN_API_BASE_URL || ""; // OpenAI's endpoint for ChatGPT
    }

    async generateText({ prompt, model = "gpt-4", maxTokens = 100, temperature = 0.7 }) {
        try {
            const response = await axios.post(
                "https://api.openai.com/v1/chat/completions",
                {
                    model: model,
                    messages: [{ role: "user", content: prompt }],
                    max_tokens: maxTokens,
                    temperature: temperature,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer sk-proj-mktWXdRmJGJuBzQTRTwdT3BlbkFJXacnPIHkW4ZNaWDhKCZi`,
                    },
                }
            );

            const choices = response.data.choices;
            console.log("Chargpt reposne::", choices[0].message.content);
            if (choices && choices.length > 0) {
                return { text: choices[0].message.content };
            } else {
                throw new Error("No valid response received from ChatGPT.");
            }
        } catch (error) {
            console.error("ChatGPT API Error:", error.response?.data || error.message);
            throw new Error("Failed to generate text from ChatGPT API.");
        }
    }

    formatPrice(priceString) {
        // Remove any existing non-numeric characters except decimal point
        const cleanedPrice = priceString.replace(/[^\d.]/g, '');

        // Convert to a number
        const number = parseFloat(cleanedPrice);

        // Check if it's a valid number
        if (isNaN(number)) {
            return '0';
        }

        // Format with thousand separators
        return number.toLocaleString('en-US', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 0
        });
    }

    async generateMainAdText({ postData }) {
        const sample = `⚡AED ${postData.caremi} per month with 0% down-payment through bank finance

▔▔▔▔▔▔▔▔▔▔
✏️ Key Details:

${postData?.carwarranty ? `✳️ Warranty: ${postData.carwarranty}` : ""}
 ${postData.carservice.length>0 ? `⚙️ Service Contract: ${postData.carservice}` : ""}
✂️ Wheel Size: ${postData.carwheel}

▔▔▔▔▔▔▔▔▔▔▔▔▔
✅ Why Choose This Car?

${postData.description}
▔▔▔▔▔▔▔▔▔▔

✅ Options : 
${postData.options}
- And Much More...

▔▔▔▔▔▔▔▔▔▔
Ⓜ️ Website:
www.albacars.ae

⛺ Location:
https://goo.gl/maps/XrGnafTmhQQuRqMV9

⏰ Timing:
7 Days (10:00 AM - 10:00 PM)

▔▔▔▔▔▔▔▔▔▔
⚡ Cash Buyers:
Provide:
1️⃣ Emirates ID
2️⃣ Driving License

▔▔▔▔▔▔▔▔▔▔
✏️ Finance Buyers:

Required Documents:

Employed:
1️⃣ Salary Certificate
2️⃣ 3-month bank statement (stamped)
3️⃣ Passport & Visa copies
4️⃣ Emirates ID copy
(If you've received only one/no salaries and work for a listed company, contact us.)

Self-Employed:
1️⃣ Trade License
2️⃣ MOA
3️⃣ Passport copies of all partners
4️⃣ Emirates ID & Visa copies
5️⃣ 3-month personal bank statement
6️⃣ 3-month company bank statement

Companies:
1️⃣ Trade License
2️⃣ MOA
3️⃣ Passport copies of all partners
4️⃣ 3-month company bank statement

▔▔▔▔▔▔▔▔▔▔
✔️ Car Reservation Options:

To start, we require a deposit of AED 5,000 via:
1️⃣ Credit/Debit Card: Refunded in cash after registration
2️⃣ Cash: Refunded in cash after registration
3️⃣ Cheque: Not cashed, returned after registration

(Terms & Conditions will be shared at booking.)

▔▔▔▔▔▔▔▔▔▔
✉️ Sell Your Car:

Fill out the form here:
https://albacars.ae/sell-used-car-dubai/

We offer cash payments and handle bank early settlements.

▔▔▔▔▔▔▔▔▔▔
⭐ Ref: 7506AC`;

        const prompt = `You are an expert car advertisement copywriter. Create a detailed car advertisement following the exact structure and style of this sample ad, but for the new car details I provide. Keep all sections, formatting, emojis, and dividers exactly the same.

Sample ad format:
${sample}

Generate a new ad for this car with these specific details:
${JSON.stringify(postData)}

Important instructions:
1. Maintain the exact same structure and sections as the sample
2. The monthly payment should be taken from the data using {postData.caremi}
3. Keep all emojis, bullet points, and divider lines (▔▔▔▔▔▔▔▔▔▔)
4. Keep all the standard information like website, location, timing, and document requirements exactly the same
5. Only change the car-specific information like model, price, warranty, and features
6. Match the tone and style of the sample description
7. Keep the formatting for finance options, cash buyers, and reservation options identical
8. End with a reference number in the same format
9. The options section should be generated based on the data provided in the postData 
10. Show Service contract only if the data is available in the postData,
11. Don't take any hardcoded values from the sample ad ignore if there is no value, for eg: if there is no warranty data, ignore showing warranty information
`;

        const chatGPTResponse = await this.generateText({
            prompt,
            maxTokens: 1500, // Increased for fuller response
            temperature: 0.7
        });

        if (!chatGPTResponse || !chatGPTResponse.text) {
            throw new Error("Failed to generate ad text");
        }

        return chatGPTResponse.text.trim();

    }


    async generateIGAdText({ postData }) {
        const sample = `🚘 Mercedes-Benz E53 AMG Coupe | 2023

💰AED ${this.formatPrice(postData.carprice)} | AED ${this.formatPrice(postData.caremi)} Per Month | 0% Downpayment 

📍 Specification: GCC
📏 Odometer: ${this.formatPrice(postData.carodometer)} km
${postData?.carwarranty ? `🛡 Warranty: ${postData.carwarranty}` : ''}
${postData.carservice.length > 0 ? `⚙️ Service Contract: ${postData.carservice}` : ''}

📞 Call / WhatsApp:
📲 +971 4 377 2502

🏢 Visit Our Showroom:
🕙 Open daily: 10:00 AM - 10:00 PM

📜 What makes this special:

${postData.description}


✅ Price is  ${postData.addexpenses != "Exclusive of VAT" ? "inclusive of VTA" : "exclusive of VAT"}

🔗 #MERCEDESBENZ #LuxuryCarsDubai #AlbaCars #UsedCarsForSale #CarLifestyle

📌 REF: 8895AC`;

        const prompt = `You are an expert car advertisement copywriter. Create a detailed car advertisement following the exact structure and style of this sample ad, but for the new car details I provide. Keep all sections, formatting, emojis, and dividers exactly the same.

Sample ad format:
${sample}

Generate a new ad for this car with these specific details:
${JSON.stringify(postData)}

Important instructions:
1. Maintain the exact same structure and sections as the sample, the 
2. Keep all emojis, bullet points, and divider lines (▔▔▔▔▔▔▔▔▔▔)
3. Keep all the standard information like website, location, timing, and document requirements exactly the same
4. Only change the car-specific information like model, price, warranty, and features
5. For the VAT, check the addexpenses field in the postData. and the price should be carprice in the postData and caremi for the monthly payment
6. Match the tone and style of the sample description
7. Keep the formatting for finance options, cash buyers, and reservation options identical
8. End with a reference number in the same format
9. The phone number should be the same as the sample ad`;

console.log("Calling ChatGPT for IG Ad");
        const chatGPTResponse = await this.generateText({
            prompt,
            maxTokens: 1500, // Increased for fuller response
            temperature: 0.7
        });

        if (!chatGPTResponse || !chatGPTResponse.text) {
            throw new Error("Failed to generate ad text");
        }

        return chatGPTResponse.text.trim();
    }
}

export default OpenAIService;